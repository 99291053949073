
import MKInput from "components/MKInput"; 
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox"; 
import MKButton from "components/MKButton"; 
import MKTypography from "components/MKTypography";  
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { Buffer } from 'buffer';
import { useState, useEffect } from "react";
import { appAuth } from "../../contexts/AppContext"

export default function AppKey({app}) { 
    
    const {  updateApp } = appAuth()
    const [modalState, setModalState] = useState({showDelete:false})

    const encodeBase64 = (data) => {
        return Buffer.from(data).toString('base64');
    }
    
    const decodeBase64 = (data) => {
        return Buffer.from(data, 'base64').toString();
    }
    const [showRawKey, setShowRawKey] = useState(false);
    const [appPublicKey, setAppPublicKey] = useState(decodeBase64(app.appPublicKey));
    const [hasUpdated, setHasUpdated] = useState(false);
    const [jwtFields, setJWTFeilds] = useState(app.jwtFields || [])
    
    const showRawKeyHanlder = () =>{

        setShowRawKey(!showRawKey)

       
    }

    const addJWTFields = () => {
        setJWTFeilds(prevItems => { 
            return [...prevItems, {name:"", value:""}];
        });  
    }

    const toggleModalDelete = (item)=> {
        setModalState({
            ...modalState,
            ['showDelete']: !modalState.showDelete,
            ['currentField']: item

        })
    } 

    const deleteJWTField = () => {
        toggleModalDelete()
        if(modalState.currentField) {
            setJWTFeilds(
                jwtFields.filter(( item) => {
                return item.name !== modalState.currentField.name
                })
            ) 
        }
    }

    const cancelJWTFields = () => {
        setJWTFeilds(app.jwtFields)
        setHasUpdated(false);
    }

    const saveJWTFields = async () => {
        console.log("jwtFields : ", jwtFields)
        let updated = await updateApp(app.appId, "jwtFields", jwtFields)

        console.log("jwtFields updated : ", updated)
    }


    const onChangeValue = (evt, index)=> {

        setJWTFeilds(jwtFields.map( (field, i ) => {
            if (i === index) {
              // Create a *new* object with changes
              setHasUpdated(true);
              return { ...field, [evt.target.name]: evt.target.value };
            } else {
              // No changes
              return field;
            }
          }));

        
    } 

    useEffect(() => {

        if(showRawKey === true) setAppPublicKey(encodeBase64(app.appPublicKey))
        else setAppPublicKey(decodeBase64(app.appPublicKey))

    }, [showRawKey])


    

    return (
        <MKBox p={2}> 

            <MKTypography variant="h5"   mb={3}>Application Keys</MKTypography>

            <MKTypography color="black" variant="body2" pr={4}>App Name: {app.name}</MKTypography>

            <MKTypography color="black" variant="body2" >App ID: {app.displayAppId}</MKTypography>

            <MKBox display="flex" alignItems="center">  
                <MKTypography color="black" variant="body2" >Public Key</MKTypography>
                <MKButton color="clear" size="large" onClick={() => {navigator.clipboard.writeText(appPublicKey)}}>
                    <Icon fontSize="large">copy</Icon>
                </MKButton> 
            </MKBox> 

            <MKBox display="flex" alignItems="center">
                <Switch checked={showRawKey}  onChange={showRawKeyHanlder}/>
                <MKTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    ml={1} >
                Show Raw Key
                </MKTypography>
            </MKBox> 

            <MKInput  disabled  multiline fullWidth rows={9} value={(appPublicKey)}  sx={{ maxWidth: 'md' }}/>

            <MKBox display="flex" alignItems="center">  
                <MKTypography color="black" variant="body2" >App Token</MKTypography>
                <MKButton color="clear" size="large" onClick={() => {navigator.clipboard.writeText(app.appToken)}} >
                    <Icon fontSize="large">copy</Icon>
                </MKButton> 
            </MKBox> 
            <MKInput  disabled  multiline fullWidth rows={10} value={app.appToken}  sx={{ maxWidth: 'md' }}/>


            <Divider sx={{ mt: 2, maxWidth: 'md'  }} />

            <MKBox  mb={2}  alignItems="center" sx={{ maxWidth: 'sm' }}>  
               
                <MKBox display="flex" alignItems="center">  
                    <MKTypography color="black" variant="body2" >JWT Feilds</MKTypography>
                    <MKButton color="clear" size="large" onClick={addJWTFields}>
                        <Icon fontSize="large">add</Icon>
                    </MKButton> 
                </MKBox> 
                 

                {
                    jwtFields.map(function(field, index){ 
                        return(
                            <MKBox display="flex"  alignitems="center" justifyContent="space-between"  m={3}>  
                                <MKInput variant="standard" label="Field Name" name="name" required  InputLabelProps={{ shrink: true }}  defaultValue={field.name} onChange={evt => onChangeValue(evt, index)}/>  

                                <MKInput variant="standard" label="Field Value" name="value" required  InputLabelProps={{ shrink: true }} defaultValue={field.value} onChange={evt => onChangeValue(evt, index)} /> 

                                <MKButton variant="gradient" color="error"  size="large" onClick={() =>  toggleModalDelete(field)} >
                                    <Icon fontSize="large">delete</Icon>
                                </MKButton> 
                            </MKBox> 
                        )
                    })
                }

                <MKButton variant="gradient" color="primary"  sx={{ marginTop: 2 }} onClick={saveJWTFields} disabled={!hasUpdated}>
                    Save
                </MKButton>

                <MKButton variant="gradient" color="info"  sx={{ marginTop: 2, marginLeft:2}} onClick={cancelJWTFields}>
                    Cancel
                </MKButton>

            </MKBox> 



            <Modal open={modalState.showDelete} onClose={toggleModalDelete} sx={{ display: "grid", placeItems: "center" }}>
                <Slide direction="down" in={modalState.showDelete} timeout={300}>
                    <MKBox
                    position="relative"
                    sx={{ maxWidth: 'md' }}
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                    >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Are you sure to delete this field?</MKTypography> 
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox p={2}>
                        <MKTypography variant="body">This transaction can't undo.</MKTypography>
                    </MKBox>
                    <Divider sx={{ my: 0 }} />
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="dark" onClick={toggleModalDelete}>
                        cancel
                        </MKButton>
                        <MKButton variant="gradient" color="primary" onClick={deleteJWTField}>
                        Submit
                        </MKButton>
                    </MKBox>
                    </MKBox>
                </Slide>
            </Modal>


        </MKBox> 
    )

}